import React from "react";
import Battlefield from "./Battlefield";
import Battlefield2 from "./Battlefield2";
import CityBattlefield from "./CityBattlefield";
import CityBattlefield2 from "./CityBattlefield2";
//import PropTypes from "prop-types";

class Battlefields extends React.Component {

    render() {
        return (
            <>
            <Battlefield pos="l" id={"left"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
            <Battlefield pos="r" id={"right"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
            <Battlefield2 pos="t" id={"top"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
            <Battlefield2 pos="b" id={"bottom"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
            <CityBattlefield pos="as" id={"athensCity-t2"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
            <CityBattlefield2 pos="ad" id={"athensCity-t3"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
            <CityBattlefield pos="ss" id={"spartaCity-t2"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
            <CityBattlefield2 pos="sd" id={"spartaCity-t3"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
            <CityBattlefield pos="ts" id={"troyCity-t2"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
            <CityBattlefield2 pos="td" id={"troyCity-t3"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
            <CityBattlefield pos="ths" id={"thebesCity-t2"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
            <CityBattlefield2 pos="thd" id={"thebesCity-t3"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
            </>
        )
    }
}

export default Battlefields;