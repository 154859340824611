import React from "react";
import DelphiBattlefield from "./DelphiBattlefield";
//import PropTypes from "prop-types";

class Delphi extends React.Component {

    render() {
        return (
            <div className="delphi-container">
                <DelphiBattlefield pos="t" id={"delphi-t2"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
                <DelphiBattlefield pos="b" id={"delphi-t4"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
                <DelphiBattlefield pos="l" id={"delphi-t1"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
                <DelphiBattlefield pos="r" id={"delphi-t3"} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveTo={this.props.canMoveTo} moveCard={this.props.moveCard} />
                <div className="delphi-temple">
                   <button className="delphi-hero-button">Hero Hire</button>
                   <div className="delphi-temple-center">
                       Delphi
                   </div>
                   <button className="delphi-oracle-button">Consult the Oracle</button> 
                </div>
            </div>
        )
    }
}

export default Delphi;