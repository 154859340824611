import React from "react";
import City from "./City";
import Battlefields from "./Battlefields";
import Delphi from "./Delphi";
import Menu from "./Menu";
import Card from "./Card";
//import PropTypes from "prop-types";

class Board extends React.Component {

    render() {
        let movingCard;
        if (this.props.movingCard.card) {
            movingCard = <Card card={this.props.movingCard.card} moving={"moving"} team={this.props.movingCard.card.type} />
        }
        return (
            <>
            <Menu 
            goToPage={this.props.goToPage}
            />
            <div className="board-map" onMouseMove={this.props.moveMovingCard}>
                <div className="moving-card">
                    {movingCard}
                </div>
                <City 
                team="athens-city"
                city="Athens"
                game={this.props.game}
                updateTeam={this.props.updateTeam}
                movingCard={this.props.movingCard}
                placeCard={this.props.placeCard}
                id="athensCity-t1"
                cards={this.props.game.cards}
                placeCitizen={this.props.placeCitizen}
                showMoves={this.props.showMoves}
                selectedCard={this.props.selectedCard}
                canMoveTo={this.props.canMoveTo}
                moveCard={this.props.moveCard}
                openCityShop={this.props.openCityShop}
                />
                <City 
                team="troy-city"
                city="Troy"
                game={this.props.game}
                updateTeam={this.props.updateTeam}
                movingCard={this.props.movingCard}
                placeCard={this.props.placeCard}
                id="troyCity-t1"
                cards={this.props.game.cards}
                placeCitizen={this.props.placeCitizen}
                showMoves={this.props.showMoves}
                selectedCard={this.props.selectedCard}
                canMoveTo={this.props.canMoveTo}
                moveCard={this.props.moveCard}
                openCityShop={this.props.openCityShop}
                />
                <City 
                team="sparta-city"
                city="Sparta"
                game={this.props.game}
                updateTeam={this.props.updateTeam}
                movingCard={this.props.movingCard}
                placeCard={this.props.placeCard}
                id="spartaCity-t1"
                cards={this.props.game.cards}
                placeCitizen={this.props.placeCitizen}
                showMoves={this.props.showMoves}
                selectedCard={this.props.selectedCard}
                canMoveTo={this.props.canMoveTo}
                moveCard={this.props.moveCard}
                openCityShop={this.props.openCityShop}
                />
                <City 
                team="thebes-city"
                city="Thebes"
                game={this.props.game}
                updateTeam={this.props.updateTeam}
                movingCard={this.props.movingCard}
                placeCard={this.props.placeCard}
                id="thebesCity-t1"
                cards={this.props.game.cards}
                placeCitizen={this.props.placeCitizen}
                showMoves={this.props.showMoves}
                selectedCard={this.props.selectedCard}
                canMoveTo={this.props.canMoveTo}
                moveCard={this.props.moveCard}
                openCityShop={this.props.openCityShop}
                />
                <Battlefields 
                movingCard={this.props.movingCard}
                placeCard={this.props.placeCard}
                cards={this.props.game.cards}
                showMoves={this.props.showMoves}
                selectedCard={this.props.selectedCard}
                canMoveTo={this.props.canMoveTo}
                moveCard={this.props.moveCard}
                />
                <Delphi 
                movingCard={this.props.movingCard}
                placeCard={this.props.placeCard}
                cards={this.props.game.cards}
                showMoves={this.props.showMoves}
                selectedCard={this.props.selectedCard}
                canMoveTo={this.props.canMoveTo}
                moveCard={this.props.moveCard}
                />
            </div>
            </>
        )
    }
}

export default Board;