import React from "react";
import CardSlot from "./CardSlot";
//import PropTypes from "prop-types";

class DelphiBattlefield extends React.Component {

    render() {
        let canMoveHere="";
        if (this.props.canMoveTo && this.props.canMoveTo.includes(`${this.props.id}`)) {
            canMoveHere="can-move-here";
        };
        return (
            <div className={`delphi-battlefield-container-${this.props.pos}`}>
                <div className={`delphi-battlefield`} id={this.props.id} >
                        <CardSlot id={`${this.props.id}-s1`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere} moveCard={this.props.moveCard} />
                        <CardSlot id={`${this.props.id}-s2`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere} moveCard={this.props.moveCard} />
                        <CardSlot id={`${this.props.id}-s3`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere} moveCard={this.props.moveCard} />   
                        <CardSlot id={`${this.props.id}-s4`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere} moveCard={this.props.moveCard} />
                        <CardSlot id={`${this.props.id}-s5`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere} moveCard={this.props.moveCard} />  
                        <CardSlot id={`${this.props.id}-s6`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere} moveCard={this.props.moveCard} />  
                </div>
            </div>
        );
    };
};

export default DelphiBattlefield;