import React from "react";
import CardSlot from "./CardSlot";
//import PropTypes from "prop-types";

class Battlefield2 extends React.Component {

    render() {
        let canMoveHere1="";
        if (this.props.canMoveTo && this.props.canMoveTo.includes(`${this.props.id}-t1`)) {
            canMoveHere1="can-move-here";
        };
        let canMoveHere2="";
        if (this.props.canMoveTo && this.props.canMoveTo.includes(`${this.props.id}-t2`)) {
            canMoveHere2="can-move-here";
        };
        return (
            <div className={`battlefield-container-${this.props.pos}`}>
                <div className={`battlefield2-t1`}>
                        <CardSlot id={`${this.props.id}-t1-s1`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere1} moveCard={this.props.moveCard} />
                        <CardSlot id={`${this.props.id}-t1-s2`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere1} moveCard={this.props.moveCard} />
                        <CardSlot id={`${this.props.id}-t1-s3`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere1} moveCard={this.props.moveCard} />   
                        <CardSlot id={`${this.props.id}-t1-s4`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere1} moveCard={this.props.moveCard} />
                        <div className="empty"></div>  
                        <CardSlot id={`${this.props.id}-t1-s5`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere1} moveCard={this.props.moveCard} />
                        <CardSlot id={`${this.props.id}-t1-s6`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere1} moveCard={this.props.moveCard} />  
                </div>
                <div className={`battlefield2-t2`}>
                        <CardSlot id={`${this.props.id}-t2-s1`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere2} moveCard={this.props.moveCard} />
                        <CardSlot id={`${this.props.id}-t2-s2`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere2} moveCard={this.props.moveCard} />
                        <CardSlot id={`${this.props.id}-t2-s3`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere2} moveCard={this.props.moveCard} />
                        <CardSlot id={`${this.props.id}-t2-s4`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere2} moveCard={this.props.moveCard} />  
                        <div className="empty"></div>  
                        <CardSlot id={`${this.props.id}-t2-s5`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere2} moveCard={this.props.moveCard} />
                        <CardSlot id={`${this.props.id}-t2-s6`} cards={this.props.cards} showMoves={this.props.showMoves} selectedCard={this.props.selectedCard} canMoveHere={canMoveHere2} moveCard={this.props.moveCard} /> 
                </div>
            </div>
        )
    }
}

export default Battlefield2;